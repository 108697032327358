import React, { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';

interface IProps {
  children: ReactNode;
}

const AppProviders: React.FC<IProps> = ({ children }) => {
  return <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>;
};

export default AppProviders;
