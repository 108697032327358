import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Pusher from 'pusher-js';
import { UserAgentApplication } from 'msal';
import { gapi } from 'gapi-script';

import { getGoogleSettings, getMicrosoftSettings, getPusherConfig } from 'services/requests';

import { IPusherConfig } from 'services/types';
import { IGoogleError, IGoogleSignInResponse, IParams } from 'types';

import { ReactComponent as MicrosoftIcon } from 'assets/microsoft.svg';
import { ReactComponent as GoogleIcon } from 'assets/google.svg';

import Loader from 'components/Loader/Loader';

interface IProps {
  onSuccess: () => void;
}

const LoginButtons: React.FC<IProps> = ({ onSuccess }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [pusherConfig, setPusherConfig] = useState<IPusherConfig | undefined>(undefined);
  const [params, setParams] = useState<IParams | undefined>(undefined);

  const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const pusher = useMemo(() => {
    if (pusherConfig?.cluster && pusherConfig?.key) {
      return new Pusher(pusherConfig.key, {
        cluster: pusherConfig.cluster,
        channelAuthorization: {
          transport: 'ajax',
          endpoint: `${process.env.REACT_APP_BASE_API_URL}/common/authorize-auth-pusher`,
        },
      });
    }
  }, [pusherConfig]);

  const handleSendMessage = (data: { token: string; type: string }): void => {
    try {
      if (pusher?.key && (localStorage.getItem('sessionId') ?? params?.sessionId)) {
        setLoading(true)
        const ch = pusher.subscribe(`private-${localStorage.getItem('sessionId') ?? params?.sessionId}`);

        ch.bind('pusher:subscription_succeeded', () => {
          ch.trigger('client-message', data);
          onSuccess();
          setLoading(false)
        });
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleMicrosoftLogin = (): void => {
    try {
      setLoading(true);
      getMicrosoftSettings().then(async res => {
        const msalObject = new UserAgentApplication({
          auth: {
            clientId: res.auth.clientId,
            authority: res.auth.authority,
            redirectUri: window.location.origin?.endsWith('/')
              ? window.location.origin
              : window.location.origin + '/',
          },
        });

        const token = await msalObject.loginPopup({
          scopes: ['User.Read'],
          prompt: 'select_account',
        });

        localStorage.setItem('sessionId', params?.sessionId ?? '')

        handleSendMessage({ type: 'microsoft', token: token.idToken.rawIdToken });
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const handleGoogleLogin = (): void => {
    setLoading(true);
    getGoogleSettings().then(data => {
      gapi.load('client:auth2', () => {
        // Initialize client with our credentials
        gapi.client
          .init({
            apiKey: data.apiKey,
            clientId: data.clientId,
            scope: 'email', // https://developers.google.com/people/v1/how-tos/authorizing
          })
          .then(
            () => {
              // Client is ready, let's roll
              const googleAuth = gapi.auth2.getAuthInstance();
              if (!googleAuth) {
                // An issue with configuration
                return;
                // return deferred.promise;
              }

              // Ask user to sign in
              googleAuth.signIn().then(
                (response: IGoogleSignInResponse): void => {
                  // Sending token via Pusher
                  handleSendMessage({
                    type: 'google',
                    token: response.getAuthResponse().id_token,
                  });
                },
                (errorSignIn: IGoogleError) => {
                  // eslint-disable-next-line no-console
                  console.log(errorSignIn.error);
                  setLoading(false);
                },
              );
            },
            (errorInit: IGoogleError) => {
              // Failed to initialize (bad credentials or so). Return an empty result
              // eslint-disable-next-line no-console
              console.log(errorInit.error);
              setLoading(false);
            },
          );
      });
    });
  };

  const onMount = useCallback(() => {
    setLoading(true);
    getPusherConfig()
      .then(res => {
        setPusherConfig(res);

        const type = searchParams.get('type');
        const sessionId = searchParams.get('sessionId');
        if (res && type && sessionId) {
          setParams({ type, sessionId });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (params && pusher) {
      if (params.type === 'Microsoft') {
        handleMicrosoftLogin();
      }
      if (params.type === 'Google') {
        handleGoogleLogin();
      }
    }
  }, [params, pusher]);

  useEffect(() => {
    if (window.location.hash.includes('#id_token') && localStorage.getItem('sessionId')) {
      handleSendMessage({ type: 'microsoft', token: window.location.hash.split('&')[0].split('=')[1] })
    }
  }, [window.location.hash, pusher]);

  useEffect(() => {
    if (params?.sessionId) {
      localStorage.setItem('sessionId', params?.sessionId)
    }
  }, [params]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loader />;
  return (
    <div className="list">
      <button className="button" onClick={handleMicrosoftLogin}>
        Microsoft
        <MicrosoftIcon />
      </button>

      <button className="button" onClick={handleGoogleLogin}>
        Google
        <GoogleIcon />
      </button>
    </div>
  );
};

export default LoginButtons;
