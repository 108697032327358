import React from 'react';

import './styles.css';

const Loader: React.FC = () => {
  return (
    <div className="container">
      <img
        src="https://alertmanagerstoragedev.blob.core.windows.net/img/loaderLogo.gif"
        alt="logo"
      />
    </div>
  );
};

export default Loader;
