import { axiosInstance } from 'services/axiosInstance';
import { AxiosResponse } from 'axios';
import { IMicrosoftSettings, IPusherConfig } from 'services/types';

export const getAppLogo = (): Promise<AxiosResponse<string>> =>
  axiosInstance.request({
    method: 'GET',
    url: 'Common/baseAppLogo',
  });

export const getPusherConfig = (): Promise<IPusherConfig> =>
  axiosInstance.request({ method: 'GET', url: 'Common/get-auth-pusher-settings' });

export const getMicrosoftSettings = (): Promise<IMicrosoftSettings> =>
  axiosInstance.request({ method: 'GET', url: 'Account/get-msal-settings' });

export const getGoogleSettings = (): Promise<any> =>
  axiosInstance.request({
    method: 'GET',
    url: 'Account/get-google-api-settings',
  });
