import React, { useCallback, useEffect, useState } from 'react';

import { getAppLogo } from 'services/requests';

import Loader from 'components/Loader/Loader';
import AppProviders from 'providers';

import './App.css';
import LoginButtons from 'components/LoginButtons';

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [appLogo, setAppLogo] = useState<string>('');
  const [showButtons, setShowButtons] = useState<boolean>(true);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const onMount = useCallback(async () => {
    setLoading(true);
    await getAppLogo()
      .then(res => {
        setAppLogo(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    onMount();
  }, []);

  if (loading) return <Loader />;
  return (
    <AppProviders>
      <div className="auth-page">
        <div className="dialog-container">
          <div className="container-logo">
            <img src={appLogo} alt="logo" className="logo" />
          </div>

          {showButtons && (
            <LoginButtons
              onSuccess={() => {
                setShowButtons(false);
                setShowSuccess(true);
              }}
            />
          )}

          {showSuccess && (
            <div className="success">
              <div>Successfully logged in.</div>
              <div> You can close this tab now.</div>
            </div>
          )}
        </div>
      </div>
    </AppProviders>
  );
};

export default App;
